import React, { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useHistory } from '@useHistory/useHistoryCompat';
import clsx from 'clsx';
import { useKeyboardShortcutListeners } from '../../../hooks/useKeyboardShortcutListeners';
import styles from './styles.module.scss';
import { AppbarItem } from './AppbarItem/AppbarItem';
import { AppbarSupportCTA } from './AppbarSupportCTA/AppbarSupportCTA';
import { SUPPORT_PERSON_DEFAULT_IMAGE_URL } from '../../../../app/core/models/support';
import { KEYMAP } from '../../../../app/shortcuts/key-map';
import { IntercomService } from '../../../../app/core/service/intercom.service';
import { getDocLink, trackDocLink } from '../../../utils/docLinkHandler';
import { HdTooltip } from '../../../components/UIElements';
import { HdSupportButton } from '../../../components/SupportButton';
import {
  RELEASE_NOTES_BASE_URL,
  TRACKER_DOCS_CTA_CLICK,
  TRACKER_VERSION_TAG_CLICK,
  TRACKER_VERSION_TAG_MOUSE_ENTER
} from '../../../../app/core/constants';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { ActivationStatusService } from '../../../../app/core/service/activation-status.service';
import { getUnreadDisplayCount } from '../../../legacy-utils/string';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import useService from '../../../hooks/useService';
import { TeamService } from '../../../../app/core/service/team.service';
import useTeamSettings from '../../../hooks/services/useTeamSettingsService';

declare const __DASHBOARD_VERSION__: any;

interface VersionInfo {
  version: string;
  versionDisplay: string;
  releaseNotesLink: string;
}

export function Appbar() {
  const globalShortcutKeys = KEYMAP.keyMaps.global.map;

  const [supportChatHidden, setSupportChatHidden] = useState(false);
  const [supportUnreadCount, setSupportUnreadCount] = useState(0);
  const [canShowOnCallSupportIndicator, setCanShowOnCallSupportIndicator] = useState(false);
  const [isActivateAllowed, setIsActivateAllowed] = useState(false);
  const [versionInfo, setVersionInfo] = useState<VersionInfo>({
    version: '',
    versionDisplay: '',
    releaseNotesLink: ''
  });
  const [activatedDrawer, setActivatedDrawer] = useState<string>();
  const [supportPersonImageURL, setSupportPersonImageURL] = useState(
    SUPPORT_PERSON_DEFAULT_IMAGE_URL
  );

  const analyticsTracker = useAnalyticsTracker();
  const { isWorkFlowsEnabled, getTeamSettings } = useTeamSettings();
  const history = useHistory();
  const location = useLocation();

  const supportService = useService(IntercomService);
  const activateStatusService = useService(ActivationStatusService);
  const { teamDetails } = useService(TeamService);

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    setSupportChatHidden(supportService.isChatHidden());
    setIsActivateAllowed(activateStatusService.shouldShowActivation());

    supportService.hideDefaultLauncher();

    supportService.isOnCallSupportAvailable$.subscribe(isOnCallSupportAvailable => {
      setCanShowOnCallSupportIndicator(isOnCallSupportAvailable);
    });

    supportService.onCallSupportPersonImage$.subscribe(imageURL => {
      setSupportPersonImageURL(imageURL);
    });

    supportService.unreadCount$.subscribe((count: number) => {
      setSupportUnreadCount(count || 0);
    });

    getVersionInfo();
  }, []);

  useEffect(() => {
    const activeDrawer = searchParams.get('drawer');
    setActivatedDrawer(activeDrawer || null);
  }, [location]);

  const getVersionInfo = () => {
    const version = __DASHBOARD_VERSION__ || '';
    let versionDisplay = version;
    let releaseNotesLink;

    let releaseVersion = version.match(/v[0-9]+\.[0-9]+/);

    if (releaseVersion) {
      releaseVersion = releaseVersion.toString().replace(/v([0-9]+)\.([0-9])$/, 'v$1.0$2');
    }

    if (releaseVersion) {
      versionDisplay = releaseVersion;
      releaseNotesLink = `${RELEASE_NOTES_BASE_URL + releaseVersion}/`;
    }

    setVersionInfo({
      version,
      versionDisplay,
      releaseNotesLink
    });
  };

  useKeyboardShortcutListeners(
    {
      'global.team': (event: KeyboardEvent) => {
        history.push('/team');
        event.preventDefault();
      },
      'global.payment': (event: KeyboardEvent) => {
        history.push('/payment');
        event.preventDefault();
      }
    },
    {
      priority: 0,
      terminal: 'match'
    }
  );

  const isLinkActive = (path: string) => !!matchPath(path, location.pathname);

  const onVersionTagMouseEnter = () => {
    analyticsTracker.eventTrack({ action: TRACKER_VERSION_TAG_MOUSE_ENTER });
  };

  const onDocsLinkClick = () => {
    trackDocLink('/', 'appbar');
    analyticsTracker.eventTrack({ action: TRACKER_DOCS_CTA_CLICK });
  };

  const onVersionTagClick = () => {
    analyticsTracker.eventTrack({ action: TRACKER_VERSION_TAG_CLICK });
  };

  const isActivateUser = () => teamDetails?.sign_up_source === 'ACTIVATE';

  const dataIdGenerator = getDataIdGenerator('appbar');

  const isJobsViewEnabled = !!getTeamSettings()?.show_jobs_view;

  return (
    <div className={styles.appbar}>
      <img
        className={styles.appbarBrandImage}
        src='https://res.cloudinary.com/hevo/image/upload/v1684393404/hevo-logos-v2/logo-mark/logo_ph2du0.png'
        alt='appbar-brand-img'
      />

      <div className={`${styles.appbarLinks} ${styles.appbarLinksBorderTop}`}>
        {isJobsViewEnabled ? (
          <AppbarItem
            navActive={isLinkActive('/all-jobs')}
            to='/all-jobs'
            activeDrawerLink={activatedDrawer}
            iconName='jobs'
            label='All Jobs'
            onClick={setActivatedDrawer}
          />
        ) : null}

        <AppbarItem
          navActive={isLinkActive('/pipeline/*')}
          to='/pipeline'
          activeDrawerLink={activatedDrawer}
          shortcut={globalShortcutKeys.pipelineList}
          iconName='pipeline'
          label='Pipelines'
          onClick={setActivatedDrawer}
        />

        <AppbarItem
          navActive={isLinkActive('/model/*') || isLinkActive('/transform/*')}
          to='/model'
          activeDrawerLink={activatedDrawer}
          shortcut={globalShortcutKeys.modelList}
          iconName='models'
          label='Models'
          onClick={setActivatedDrawer}
        />

        {isActivateAllowed ? (
          <AppbarItem
            styles={{ order: isActivateUser() ? -1 : 2 }}
            navActive={isLinkActive('/activation/*') || isLinkActive('/target/*')}
            to='/activation'
            activeDrawerLink={activatedDrawer}
            shortcut={globalShortcutKeys.activateList}
            iconName='activate'
            label='Activate'
            onClick={setActivatedDrawer}
          />
        ) : null}

        {isWorkFlowsEnabled ? (
          <AppbarItem
            navActive={isLinkActive('/workflow/*')}
            to='/workflow'
            activeDrawerLink={activatedDrawer}
            shortcut={globalShortcutKeys.workflowList}
            iconName='workflow'
            label='Workflows'
            onClick={setActivatedDrawer}
          />
        ) : null}

        <AppbarItem
          navActive={isLinkActive('/destination/*')}
          to='/destination'
          activeDrawerLink={activatedDrawer}
          shortcut={globalShortcutKeys.warehouseList}
          iconName='destinations'
          label='Destinations'
          onClick={setActivatedDrawer}
        />
      </div>

      <div className={`${styles.appbarLinks} ${styles.appbarLinksBottom}`}>
        <AppbarItem
          to={getDocLink('/', 'appbar')}
          target='_blank'
          isExternalLink
          iconName='documentation'
          activeDrawerLink={activatedDrawer}
          label='Docs'
          onClick={onDocsLinkClick}
        />

        {!supportChatHidden ? (
          <HdSupportButton
            iconName={null}
            label='Live Chat'
            bgColor='primary'
            badgeContent={supportUnreadCount ? getUnreadDisplayCount(supportUnreadCount) : null}
            showOnCallSupportIndicator={canShowOnCallSupportIndicator}
            supportPersonImageURL={supportPersonImageURL}
            Component={AppbarSupportCTA}
          />
        ) : null}

        <div className={styles.appbarVersionTagWrapper}>
          <HdTooltip title={versionInfo.version} placement='right'>
            <a
              data-id={dataIdGenerator('versionTag')}
              className={clsx(styles.appbarVersionTag)}
              onMouseEnter={onVersionTagMouseEnter}
              onClick={onVersionTagClick}
              target='_blank'
              href={getDocLink(versionInfo.releaseNotesLink || './', 'appbar')}
              rel='noreferrer'
            >
              {versionInfo.versionDisplay}
            </a>
          </HdTooltip>
        </div>
      </div>
    </div>
  );
}
