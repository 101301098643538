import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RouteDeactivateRequestService {
  private _onDeactivateRequest$ = new Subject<boolean>();
  private _onDeactivateResult$ = new Subject<boolean>();

  onDeactivateRequest$ = this._onDeactivateRequest$.asObservable();

  canDeactivate() {
    this._onDeactivateRequest$.next(true);
    return this._onDeactivateRequest$.observers.length ? this._onDeactivateResult$ : of(true);
  }

  onDeactivateCallback(result: boolean) {
    this._onDeactivateResult$.next(result);
  }
}
