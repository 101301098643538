/* eslint-disable camelcase */
import {
  ATTRIBUTION_SETTINGS,
  CLICK_SUFFIX,
  DEFAULT_AGGREGATION_LEVEL,
  DEFAULT_CLICK_ATTRIBUTION_WINDOW,
  DEFAULT_REPORT_TIME,
  DEFAULT_VIEW_ATTRIBUTION_WINDOW,
  VIEW_SUFFIX
} from './constants';
import {
  AggregationLevelsFactory,
  AggregationTimesFactory,
  FieldListFactory,
  getSelectorInclusionState
} from './utils';
import { SourceTypeConfigHelper } from '../../../../../../app/nodes/source-settings-form/config-helper';
import { getSelectorRawDataFromInclusionState } from '../../../../../../app/shared/single-level-selector/model';
// eslint-disable-next-line import/no-cycle
import PipelinesAPI from '../../../PipelinesAPI';
import { FBABreakdownGroups } from './interface';
import { toArray, removeDuplicatesFromArrayOfPrimitives } from '../../../../../utils/helper';
import { InclusionItemKey } from '../../../../../../app/core/models/inclusion-list-v2';

export class FbaConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any, metaData: any) {
    const attributionSetting = Object.values(ATTRIBUTION_SETTINGS).find(
      value => value === (rawData?.attribution_setting || ATTRIBUTION_SETTINGS.custom)
    );

    return {
      reportTime: rawData.action_report_time,
      clickAttributionWindow: toArray(rawData.attribution_window).find((key: string) =>
        key.includes(CLICK_SUFFIX)
      ),
      viewAttributionWindow: toArray(rawData.attribution_window).find((key: string) =>
        key.includes(VIEW_SUFFIX)
      ),
      selectedAccounts: getSelectorInclusionState(
        rawData.ad_account && !rawData.ad_accounts ? [rawData.ad_account] : rawData.ad_accounts,
        rawData.all_select
      ),
      customReport: rawData.custom_report.toString(),
      thumbnailWidth: rawData.ad_creative_thumbnail_width,
      thumbnailHeight: rawData.ad_creative_thumbnail_height,
      attributionSetting,
      ...(rawData.custom_report
        ? this._getCustomReportsConfigFromRawData(rawData, metaData)
        : this._getDefaultCustomReportsConfig(metaData))
    };
  }

  getDefaultConfig(metaData: any) {
    return {
      clickAttributionWindow: DEFAULT_CLICK_ATTRIBUTION_WINDOW,
      viewAttributionWindow: DEFAULT_VIEW_ATTRIBUTION_WINDOW,
      reportTime: DEFAULT_REPORT_TIME,
      attributionSetting: ATTRIBUTION_SETTINGS.custom,
      ...this._getDefaultCustomReportsConfig(metaData)
    };
  }

  buildConnectionParams(data: any) {
    const selectionRawData = getSelectorRawDataFromInclusionState(data.selectedAccounts);
    const selectionFieldsData = getSelectorRawDataFromInclusionState(data.fieldsList);
    const selectionBreakdownsData = getSelectorRawDataFromInclusionState(data.breakdownsList);

    const attributionWindows = [];

    let { attributionSetting } = data;

    if (attributionSetting === ATTRIBUTION_SETTINGS.custom) {
      attributionSetting = undefined;
      if (data.clickAttributionWindow) {
        attributionWindows.push(data.clickAttributionWindow);
      }

      if (data.viewAttributionWindow) {
        attributionWindows.push(data.viewAttributionWindow);
      }
    }

    let params: any = {
      action_report_time: data.reportTime,
      attribution_window: attributionWindows,
      custom_report: data.customReport,
      ad_creative_thumbnail_height: data.thumbnailHeight ? Number(data.thumbnailHeight) : undefined,
      ad_creative_thumbnail_width: data.thumbnailWidth ? Number(data.thumbnailWidth) : undefined,
    };

    if (attributionSetting) {
      params.attribution_setting = attributionSetting;
    }

    if (selectionRawData) {
      const selectionDTO = {
        all_select: selectionRawData.all_select,
        ad_accounts: selectionRawData?.entities
      };

      params = {
        ...params,
        ...selectionDTO
      };
    }

    if (data.customReport) {
      const [breakdowns, actionBreakdowns] = toArray(selectionBreakdownsData?.entities).reduce(
        (filteredBreakdowns, currentBreakdown) => {
          if (data?.breakdownSelectedGroups?.[currentBreakdown] !== FBABreakdownGroups.ACTION) {
            filteredBreakdowns[0].push(currentBreakdown);
          } else {
            filteredBreakdowns[1].push(currentBreakdown);
          }
          return filteredBreakdowns;
        },
        [[], []]
      );

      params = {
        ...params,
        aggregation_value: data.aggregationTime?.value,
        aggregation_unit: data.aggregationTime?.unit,
        report_level: data.level?.value,
        fields: removeDuplicatesFromArrayOfPrimitives([...toArray(selectionFieldsData?.entities)]),
        breakdown: breakdowns,
        action_breakdown: actionBreakdowns
      };
    }

    return params;
  }

  staticDependenciesResolver() {
    return Promise.all([
      PipelinesAPI.getFBAConnectionFields(),
      PipelinesAPI.getFBBreakdownsDisplayData(),
      PipelinesAPI.getFBFieldsData()
    ]).then(
      ([
        {
          data: { facebook_ads_pre_defined_report_config, facebook_ads_custom_report_config }
        },
        breakdownsDisplay,
        fieldsDisplay
      ]: any) => ({
        reportTimes: facebook_ads_pre_defined_report_config.report_times,
        breakdowns: removeDuplicatesFromArrayOfPrimitives([
          ...facebook_ads_custom_report_config.breakdowns,
          ...facebook_ads_custom_report_config.action_breakdowns
        ])
          .filter(
            breakdown =>
              ![
                'app_id',
                'skan_conversion_id',
                'is_conversion_id_modeled',
                'skan_campaign_id',
                'mmm',
                'breakdown_reporting_ad_id',
                'is_rendered_as_delayed_skip_ad',
                'landing_destination',
                'media_type',
                'matched_persona_id',
                'matched_persona_name'
              ].includes(breakdown)
          )
          .map(breakdown => ({ value: breakdown, ...breakdownsDisplay[breakdown] })),
        fields: FieldListFactory(facebook_ads_custom_report_config.fields.fields, fieldsDisplay),
        defaultFields:
          facebook_ads_custom_report_config?.dimensions?.default_options ||
          facebook_ads_custom_report_config?.fields?.default_options,
        clickAttributionWindows: facebook_ads_custom_report_config.click_attribution_windows,
        viewAttributionWindows: facebook_ads_custom_report_config.view_attribution_windows,
        aggregations: AggregationTimesFactory(
          facebook_ads_custom_report_config.aggregations.aggregations_list
        ),
        defaultAggregationTime: facebook_ads_custom_report_config.aggregations.default_option,
        levels: AggregationLevelsFactory(facebook_ads_custom_report_config.levels),
        incompatibleSelections: facebook_ads_custom_report_config.incompatible_selections
      })
    );
  }

  private _getDefaultCustomReportsConfig(metaData: any) {
    return {
      aggregationTime: metaData.aggregations.find(
        aggregation =>
          aggregation.valueWithUnit ===
          metaData.defaultAggregationTime.value + metaData.defaultAggregationTime.unit
      ),
      level: metaData.levels.find(level => level.value === DEFAULT_AGGREGATION_LEVEL),
      fields: toArray(metaData.defaultFields).map(field =>
        metaData.fields.find(displayData => displayData.value === field)
      ),
      fieldsList: getSelectorInclusionState(
        toArray(metaData.defaultFields).filter(
          field => metaData.fields.find(displayData => displayData.value === field)?.id
        ),
        false
      ),
      breakdownsList: getSelectorInclusionState([], false)
    };
  }

  private _getCustomReportsConfigFromRawData(rawData: any, metaData: any) {
    const filteredFields = removeDuplicatesFromArrayOfPrimitives(
      rawData.fields
    ) as InclusionItemKey[];

    return {
      fieldsList: getSelectorInclusionState(
        filteredFields.filter(field =>
          metaData.fields.find(displayData => displayData.value === field)
        ),
        false
      ),
      breakdowns: [...toArray(rawData.breakdown), ...toArray(rawData.action_breakdown)].map(
        breakdown => metaData.breakdowns.find(displayData => displayData.value === breakdown)
      ),
      breakdownsList: getSelectorInclusionState(
        [...rawData.breakdown, ...rawData.action_breakdown],
        false
      ),
      aggregationTime: metaData.aggregations.find(
        aggregation =>
          aggregation.valueWithUnit === rawData.aggregation_value + rawData.aggregation_unit
      ),
      level: metaData.levels.find(level => level.value === rawData.report_level)
    };
  }
}
