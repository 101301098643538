import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ToasterService } from '../../toaster/toaster.service';
import { CONNECTION_ERROR_MSG, DEFAULT_NETWORK_REQ_OPTIONS, REQUEST_TIMED_OUT_ERROR_MSG } from '../constants';
import { HTTPRequestMethodEnum, NetworkRequestOptions, RequestUIOptions } from '../models/request';
import { getErrorMessageFromObj } from '../../../react/legacy-utils/request';
import { retryBackoff } from '../../../react/legacy-utils/rxjs-operators';
import { GlobalBlockingLoaderService } from './global-blocking-loader.service';
import { UnauthorisedEventService } from './unauthorised-event.service';


const BACKOFF_MAX_DELAY = 30000;
const BACKOFF_INIT_DELAY = 1000;

@Injectable()
export class RxRequestService {
  constructor(
    private http: HttpClient,
    private loaderService: GlobalBlockingLoaderService,
    private toasterService: ToasterService,
    private _unauthorisedEventService: UnauthorisedEventService
  ) {
  }

  private _defaultOptions = DEFAULT_NETWORK_REQ_OPTIONS;

  unauthorisedErrorObs = this._unauthorisedEventService.unauthorisedErrorObs;

  get(url: string, networkOpts: NetworkRequestOptions = {}) {
    return this.sendRequest(HTTPRequestMethodEnum.GET, url, undefined, networkOpts);
  }

  post(url: string, networkOpts: NetworkRequestOptions = {}, params?: any) {
    return this.sendRequest(HTTPRequestMethodEnum.POST, url, params, networkOpts);
  }

  put(url: string, networkOpts: NetworkRequestOptions = {}, params?: any) {
    return this.sendRequest(HTTPRequestMethodEnum.PUT, url, params, networkOpts);
  }

  delete(url: string, networkOpts: NetworkRequestOptions = {}) {
    return this.sendRequest(HTTPRequestMethodEnum.DELETE, url, undefined, networkOpts);
  }

  sendRequest(
    method: HTTPRequestMethodEnum,
    url: string,
    params?: any,
    opts: NetworkRequestOptions = {}
  ): Observable<any> {

    const networkOptions: any = Object.assign(
      {},
      this._defaultOptions.networkOptions,
      opts.networkOptions
    );

    const uiOptions: RequestUIOptions = Object.assign(
      {},
      this._defaultOptions.uiOptions,
      opts.uiOptions
    );

    const reqFunction: any = this._generateRequestFunction(
      method,
      url,
      params,
      networkOptions
    );

    return of([]).pipe(
      tap(() => {
        this._showLoader(uiOptions);
      }),
      switchMap(() => {
        return reqFunction();
      })
    ).pipe(
      // Cancel request if the request requires auth and unauthorised error fired
      takeUntil(
        this.unauthorisedErrorObs.pipe(
          filter(() => {
            return opts.requireAuth;
          })
        )
      ),
      retryBackoff({
        initialInterval: BACKOFF_INIT_DELAY,
        maxInterval: BACKOFF_MAX_DELAY,
        shouldRetry: (error) => {
          if (!error.status || method !== HTTPRequestMethodEnum.GET) {
            return false;
          }
          return [ 502, 503, 504 ].includes(error.status);
        }
      }),
      map((res: any) => {
        if (uiOptions.showSuccessMsg) {
          const displayMessage: string = uiOptions.successMsg || this._getRespMsg(res);
          if (displayMessage) {
            this.toasterService.pop('success', undefined, displayMessage);
          }
        }

        return res;
      }),
      catchError((err: any) => {
        if (networkOptions.responseType === 'text') {
          try {
            err['error'] = JSON.parse(err.error);
          } catch (e) {
            // Do nothing
          }
        }

        const errorMsg = this._getErrorMessage(err);

        if (errorMsg && this._shouldShowErrorMsg(err, uiOptions) && err.status !== 403) {
          this.toasterService.pop('error', undefined, errorMsg);
        }

        if (uiOptions.handleTimeoutError && err.status === 504) {
          if (!!err.error?.error_message) {
            err.error.error_message = REQUEST_TIMED_OUT_ERROR_MSG
          }
          else if (!!err.error?.message) {
            err.error.message = REQUEST_TIMED_OUT_ERROR_MSG
          }
          else {
            err.message = REQUEST_TIMED_OUT_ERROR_MSG
          }
        }

        // Emit unauthorisedErrorObs when an api that requires auth return 401
        if (uiOptions.handleUnauthorisedResponse && err.status === 401) {
          this.unauthorisedErrorObs.next(uiOptions.unauthorisedHandleNext);
        }

        return throwError(err);
      }),
      finalize(() => {
        this._hideLoader(uiOptions);
      })
    );
  }

  private _generateRequestFunction(
    method: HTTPRequestMethodEnum,
    url: string,
    params?: any,
    options?: any
  ): any {

    switch (method) {
      case HTTPRequestMethodEnum.GET: {
        return this.http.get.bind(this.http, url, options);
      }

      case HTTPRequestMethodEnum.POST: {
        return this.http.post.bind(this.http, url, params, options);
      }

      case HTTPRequestMethodEnum.DELETE: {
        return this.http.delete.bind(this.http, url, options);
      }

      case HTTPRequestMethodEnum.PUT: {
        return this.http.put.bind(this.http, url, params, options);
      }
    }

  }

  private _getErrorMessage(error: any) {
    // Offline mode and Adblocker handling
    if (error.status <= 0 ) {
      return CONNECTION_ERROR_MSG;
    }

    if ([504,503].includes(error.status)) {
      return REQUEST_TIMED_OUT_ERROR_MSG;
    }

    if (typeof error.error === 'string') {
      return error.error;
    }

    const err: any = Object.assign({}, error);
    return getErrorMessageFromObj(err);
  }

  private _getRespMsg(response: any) {
    if (!response) {
      return;
    }

    const res: any = Object.assign({}, response);
    return res.body ? res.body.success_message : res.success_message;
  }

  private _shouldShowErrorMsg(err: any, uiOptions: RequestUIOptions): boolean {
    if (uiOptions.handleUnauthorisedResponse && err.status === 401) {
      return true;
    }

    if (err.status <= 0) {
      return true;
    }

    return uiOptions.showErrorMsg;
  }

  private _hideLoader(uiOptions: RequestUIOptions) {
    if (uiOptions.showLoading) {
      this.loaderService.pop();
    }
  }

  private _showLoader(uiOptions: RequestUIOptions) {
    if (uiOptions.showLoading) {
      this.loaderService.push();
    }
  }
}
